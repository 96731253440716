// CommonTable.scss
table {
  width: 95%;
  border-collapse: collapse;
  margin: 1rem 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: left;

  th, 
  td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
      border-right: none;
    }
  }

  th {
    background-color: #f8f9fa;
    color: #333;
    font-weight: bold;
    // position: sticky;
    top: 0;
    z-index: 10;
  }

  tbody {
    tr {
      &:hover {
        background-color: #f2f2f2;
      }
    }

    td {
      position: relative;

      &:last-child {
        text-align: right;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #eaeaea;
      }
    }
  }
}
