// EditOfferModal.scss
.edit-offer-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; // Ensure it's above other content

  .edit-offer-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .modal-close-btn {
      float: right;
      font-size: 1.5rem;
      border: none;
      background: none;
      cursor: pointer;
      color: #000;
        &:hover {
          color: #000;  // Change color on hover for better visibility
        }
    }

    h2 {
      clear: both;
      color: #333;
      margin-bottom: 20px;
    }

    form {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 10px;
        margin-right: 10px;

        font-weight: bold;

        input[type="text"],
        input[type="url"],
        input[type="date"],
        input[type="number"],
        input[type="file"],
        textarea {
          width: 80%;
          // width: 10rem;
          padding: 10px 15px;
          margin-top: 5px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        input[type="checkbox"] {
          margin-top: 5px;
        }
      }

      .offer-image-preview {
        width: 100%;
        max-height: 200px;
        object-fit: contain;
        margin-bottom: 10px;
      }

      button {
        padding: 10px 15px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: darken(#007bff, 10%);
        }
      }

      select {
        width: 100%;
        // padding: 0.5rem;
        padding: 10px 15px;
        margin-top: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        &:focus {
          border-color: #007bff;
          outline: none;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
    }
  }
}
