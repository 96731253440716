.add-offer-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; // Ensure it's above other content

  .heading{
    display: flex;
    justify-content: space-between;
  }
  
  .add-offer-modal {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;

    .modal-close-btn {
      float: right;
      font-size: 1.5rem;
      border: none;
      background: none;
      cursor: pointer;
      color: #000;
      &:hover {
        color: #000;  // Change color on hover for better visibility
      }
    }

    h2 {
      clear: both;
      color: #333;
      margin-bottom: 1rem;
    }

    form {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 1rem;
        font-weight: bold;

        input {
          width: 100%;
          margin-top: 0.5rem;
          padding: 0.5rem;
          border: 1px solid #ddd;
          border-radius: 4px;
          &:focus {
            border-color: #007bff;
            outline: none;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          }
        }

        input[type="checkbox"] {
          margin-top: 0;
          width: 2rem;
        }
      }


      textarea {
        width: 100%;
        padding: 0.5rem;
        margin-top: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        &:focus {
          border-color: #007bff;
          outline: none;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }

      select {
        width: 100%;
        padding: 0.5rem;
        margin-top: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        &:focus {
          border-color: #007bff;
          outline: none;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }

      button {
        background-color: #007bff;
        color: white;
        padding: 0.6rem 1rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: darken(#007bff, 10%);
        }
      }
    }
  }
}

.instruction-label{
  margin-bottom: 1rem;
  font-weight: 500 !important;
}
