.sidebar {
  box-shadow: rgba(0, 0, 0, 0.1) 8px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 20px;
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  // border-right: 1px dashed  rgba(145, 158, 171, 0.24);
  // width: 50px;
  // overflow-x: hidden;
  .head {
    padding-bottom: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      background: rgb(56, 56, 240);
      padding: 15px 5px;
    }
    .avatar p {
      font-weight: 700;
      color: #fff;
      font-size: 20px;
    }
    .adminName{
      & p:first-child{
        font-size: 13px;
        color: rgb(90, 87, 87);
      }
    }
  }
  &__option {
    padding: 15px 0;
    transition: all ease 0.2s;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid #eee;
    &:nth-child(2) {
      border-top: 1px solid #eee;
    }
    img {
      width: 18px;
    }
    &:hover {
      padding-left: 10px;
      cursor: pointer;
      & a,
      & img {
        opacity: 1;
      }
    }
    a {
      color: #010203;
      font-size: 14px;
      transition: all ease 0.5s;
      font-family: "Inter", sans-serif;
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}


.sidebutton {
  a {
    text-decoration: none;
    color: #010203;
    font-size: 14px;
    transition: all ease 0.5s;
    font-family: "Inter", sans-serif;
    text-decoration: none;
    gap: 8px;
  }
}