.stats-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0.5rem; // Maintain some space between the cards
  padding: 1rem;
  text-align: center;
  width: 15rem;
  // width: calc(33.333% - 1rem); // Subtracting the total margin from the width for 3 cards in a row

  &:not(:last-child) {
    margin-right: 1rem; // Adds right margin to all cards except the last one
  }

  .card-title {
    color: #333;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  .card-number {
    font-size: 2em;
    font-weight: bold;
    color: #000;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .stats-card {
    width: calc(50% - 1rem); // For smaller screens, we want only 2 cards in a row
  }
}

@media (max-width: 480px) {
  .stats-card {
    width: 100%; // On even smaller screens, each card takes up the full row
  }
}
