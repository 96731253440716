.user-info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-info-modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  overflow-y: auto;


  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: #000;

    cursor: pointer;
    &:hover {
      color: #000;  // Change color on hover for better visibility
    }
  }

  h2 {
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;

    .side-by-side {
      display: flex;
      justify-content: 'flex-start';

      label {
        margin-bottom: 15px;
        flex: 1;
        margin-right: 10px; // Give some space between the labels

        &:last-child {
          margin-right: 0; // Remove margin for the last item
        }
      }
    }

    label {
      margin-bottom: 15px;
      font-weight: bold;
      span {
        font-weight: normal;
      }

      input[type="checkbox"] {
        margin-left: 10px;
        width: 2rem;
      }

      select {
        width: 10rem;
        padding: 0.5rem;
        margin-top: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        &:focus {
          border-color: #007bff;
          outline: none;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
    }

    button {
      padding: 10px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: darken(#007bff, 10%);
      }
    }
  }
}

