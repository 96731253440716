.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f7fa;

  .login-container {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;

    .login-title {
      color: #5e5e5e;
      text-align: center;
    }

    .login-instruction {
      text-align: center;
      color: #a1a1a1;
      margin-bottom: 2rem;
    }

    .login-form {
      .form-group {
        margin-bottom: 1rem;

        .form-control {
          width: 100%;
          padding: 0.75rem;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          margin-bottom: 1rem;
        }
      }

      .form-checkbox {
        margin-bottom: 1rem;
      }

      .btn-login {
        background-color: #624de3;
        color: white;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 4px;
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: darken(#624de3, 10%);
        }
      }

      .forgot-password {
        display: block;
        text-align: center;
        margin: 1rem 0;
        color: #624de3;
        &:hover {
          text-decoration: underline;
        }
      }

      .login-footer {
        .sign-up {
          text-align: center;
          margin: 1rem 0;
        }
      }
    }
  }
}
